import React from 'react';
import { styled } from "../../../stitches.config";

import Box from '../Box'
import { Wrapper } from "./ACommonStyles";
import { getPcsUrls } from "./AHelpers";

export default props => {
	const pcs = getPcsUrls(props.settings.settings?.pcs_background_mobile, props.settings.settings?.pcs_background_desktop);
	return (
		<BlockWrapper>
			<section className="container" data-tb-region={props.blockSettings.tb_region}>
				<Wrapper className="row" pcs={pcs}>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b1" type="BPhotoOnTop" settings={props.settings.b1} withBackground={props.isDarkMode ? false : props.blockGroupHasBackground} classes="" />
					</div>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b2" type="BPhotoOnTop" settings={props.settings.b2} withBackground={props.isDarkMode ? false : props.blockGroupHasBackground} classes="" />
					</div>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b3" type="BPhotoOnTop" settings={props.settings.b3} withBackground={props.isDarkMode ? false : props.blockGroupHasBackground} classes="" />
					</div>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b4" type="BPhotoOnTop" settings={props.settings.b4} withBackground={props.isDarkMode ? false : props.blockGroupHasBackground} classes="" />
					</div>
				</Wrapper>
			</section>
		</BlockWrapper>
	);
}

const BlockWrapper = styled("div", {
	marginTop: "33px",
	"@bp4": {
		marginTop: "40px",
	},
});